@tailwind base;
@tailwind components;
@tailwind utilities;

.fixed-width {
  width: 50px;
  height: 50px;
  /* round corners */
}

.card-size {
  min-width: 350px;
  /* round corners */
}
.react-pdf__Page__textContent,
.annotationLayer {
  display: none;
}
